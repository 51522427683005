import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer";
import { P, OL, UL, Content, Section, H2, LinkText } from "../styles/TCandPrivacyStyles";
import { Container } from "../components/global";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const DataDeletion = () => {
  return (
    <Layout>
      <SEO title="Data Deletion" />
      <Navigation background="white" />
      <Header {...pageData.headerData} />
      <Container>
        <Content>
          <Section>
            <P>
              <H2>How do I delete my OPay data that I have added on Facebook?</H2>
            </P>
            <OL>
              <li>
                Login to your{" "}
                <OutboundLink href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  Facebook
                </OutboundLink>{" "}
                account
              </li>
              <li>
                After a successful login, click{" "}
                <img
                  alt="account"
                  referrerpolicy="origin-when-cross-origin"
                  src="https://static.xx.fbcdn.net/assets/?revision=402018541820344&amp;name=Comet-FB-Arrow&amp;density=1"
                ></img>{" "}
                in the top right of Facebook.
              </li>
              <li>Select Settings & Privacy, then click Settings.</li>
              <li>In the left menu, click Apps and Websites.</li>
              <li>
                Scroll to OPay app and click <b>Remove</b>.
              </li>
              <li>
                You may select these options: <br />
                <UL listStyle="square">
                  <li>Delete posts, videos or events [app or website] posted on your timeline.</li>
                  <li>
                    Allow Facebook to notify OPay that your login connection was removed. They may offer you another way
                    to log in.
                  </li>
                </UL>
              </li>
              <li>
                Click <b>Remove</b> again to confirm.
              </li>
            </OL>
          </Section>

          <Section>
            <small>Disclaimer: This information is from Facebook. For more information, visit{" "}
            <OutboundLink
              href="https://www.facebook.com/help/170585223002660"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkText> Facebook&apos;s Help Center..</LinkText>
            </OutboundLink></small>
          </Section>
        </Content>
      </Container>
      <Footer />
    </Layout>
  );
};

export default DataDeletion;

const pageData = {
  headerData: {
    captionText: "Data Deletion",
  },
};
